import React from 'react'
import styled from 'styled-components'
import MetaHeader from '../components/MetaHeader'
import Layout from '../layouts'
import NewsletterForm from '../components/NewsletterForm'
import PageWrapper from '../components/PageWrapper'

const Wrapper = styled.div`
  margin: 2rem auto 4rem;
`

const ApplicationSuccess = () => (
  <Layout>
    <MetaHeader title="Newsletter Sign Up" hidden />

    <Wrapper>
      <PageWrapper>
        <h2>Newsletter Sign Up</h2>
        <NewsletterForm title="" />
      </PageWrapper>
    </Wrapper>
  </Layout>
)

export default ApplicationSuccess
