import React from 'react'
import styled from 'styled-components'

import Button from './Button'
import { Input } from './common/form'
import { colors, vars } from './theme'

const Wrapper = styled.div`
  text-align: center;

  form {
    max-width: none;
  }
  input {
    margin-bottom: 0;
  }
`
const NewsletterTitle = styled.h3`
  margin-top: 0;
  color: ${colors.secondaryDark};
`
const Disclaimer = styled.div`
  margin-top: 0.5rem;
  font-size: 12px;
  color: ${colors.grey};
`
const FormContainer = styled.div`
  margin-top: 0.5rem;
  display: grid;
  align-items: end;
  grid-gap: 0.75rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    'f l '
    'c e'
    's s';

  @media screen and (min-width: ${vars.screenMedium}) {
    grid-gap: 0.5rem;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
      'f f l l c c'
      '. e e s s .';
  }

  @media screen and (min-width: ${vars.screenLarge}) {
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas:
      '. f f l l c c .'
      '. . e e s s . .';
  }
`
const FName = styled(Input)`
  grid-area: f;
`
const LName = styled(Input)`
  grid-area: l;
`
const Email = styled(Input)`
  grid-area: e;
`
const Location = styled(Input)`
  grid-area: c;
`
const SubmitButton = styled(Button)`
  padding: 0.25em 1em;
  font-size: 1rem;
  width: 100%;
  grid-area: s;
  align-self: end;
  height: 2.5rem;
  margin-top: 0.25rem;

  @media screen and (min-width: ${vars.screenMedium}) {
    height: 2.125rem;
    margin-top: 0;
    font-size: 0.875rem;
  }
`

const NewsletterForm = ({
  title = 'Subscribe to our Newsletter',
  subtitle = 'Get investment education, interviews with angels and VCs, and exclusive event access.',
}) => (
  <Wrapper>
    <NewsletterTitle>{title}</NewsletterTitle>
    <p>{subtitle}</p>
    <form
      name="femaleFundersSubscribe"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/subscribe-success">
      <input type="hidden" name="form-name" value="femaleFundersSubscribe" />
      <input type="hidden" name="lifecycleStage" value="subscriber" />
      <div data-netlify-recaptcha="true" />
      <FormContainer>
        <FName name="firstName" type="text" label="First Name" />
        <LName name="lastName" type="text" label="Last Name" />
        <Location
          name="location"
          type="text"
          label="Your Location"
          placeholder="City, State/Province"
        />
        <Email name="email" type="email" label="Email" />
        <SubmitButton primary submit>
          Sign up
        </SubmitButton>
      </FormContainer>

      <Disclaimer>Newletters sent monthly. Unsubscribe at any time.</Disclaimer>
    </form>
  </Wrapper>
)

export default NewsletterForm
